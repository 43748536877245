import { browser } from "$app/environment";
import { db } from "$lib/firebase/init";
import { collection, onSnapshot } from "firebase/firestore";
import { readable } from "svelte/store";
declare type Query = import("firebase/firestore").Query;

export const collectionAsStore = (path) => queryAsStore(collection(db, path));

export const queryAsStore = (query: Query) => {
  const { subscribe } = readable<Array<any>>([], (set) => {
    let unsubscribe = () => {};
    async function init() {
      if (browser) {
        unsubscribe = onSnapshot(query, (snap) =>
          set(
            snap.docs.map((doc) => {
              return { id: doc.id, ...doc.data() };
            })
          )
        );
      }
    }
    init();

    return unsubscribe;
  });

  return { subscribe };
};
